export default defineNuxtPlugin(async () => {
    const auth = useAuthStore();
    const route = useRoute();
    const toast = useToast();

    if (route.path.startsWith("/auth/steam/callback")) {
        await auth.callback(route.query as any).catch(() => {
            toast.add({
                title: "Couldn't log you in, please try again",
                color: "red"
            });
        });
    } else if (route.path.startsWith("/auth/steam/manual-callback")) {
        await auth.callback(route.query, true).catch(() => {
            toast.add({
                title: "Couldn't log you in, please try again",
                color: "red"
            });
        });
    }

    await auth.load();
});
