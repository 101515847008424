import { useLocalStorage } from "@vueuse/core";

export default defineNuxtPlugin({
    name: "splashScreen",
    parallel: true,
    async setup(nuxtApp) {
        const socket = useSocketStore()();
        const auth = useAuthStore();
        const route = useRoute();
        const xmas = useLocalStorage("xmas", false);

        let content = `
            <div class="flex h-full w-full flex-col items-center justify-end py-10" style="background: url(/images/backgrounds/9.png) no-repeat center;background-size: cover;">
                <p class="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 text-3xl text-textGray-0">Loading...</p>

                <div class="relative">
                <img src='/images/logo_sparks.png' class='absolute inset-0 top-1/2 -translate-y-1/2 animate-pulse' />
                <img src='/images/branding/logo.png' class='relative animate-pulse' />
                </div
            </div>
        `;

        if (xmas.value) {
            content = `
            <div class="flex h-full w-full flex-col items-center justify-center py-10" style="background: url(/images/backgrounds/9.png) no-repeat center;background-size: cover;">
                <img src='/images/christmas/logo_splash.svg' class='relative animate-pulse' />
            </div>`;
        }

        const el = document.createElement("div");
        el.classList.add(
            "fixed",
            "z-[99999999]",
            "top-0",
            "left-0",
            "w-screen",
            "h-dvh",
            "bg-main",
            "transition-opacity",
            "duration-300"
        );
        el.id = "splashScreen";
        el.innerHTML = content;
        document.body.appendChild(el);

        const hasLoaded = ref({
            page: false,
            maintenance: false
        });

        nuxtApp.hook("page:loading:end", () => {
            hasLoaded.value.page = true;
        });

        nuxtApp.hook("app:error", () => {
            hasLoaded.value.page = true;
        });

        watch(
            () => socket.config,
            () => {
                // if user is banned, don't care whether to show their maintenance
                if (route.path === "/block/banned") {
                    hasLoaded.value.maintenance = true;
                    return;
                }

                hasLoaded.value.maintenance =
                    socket.config?.maintenance !== undefined;
            },
            {
                immediate: true,
                deep: true
            }
        );

        watch(
            [hasLoaded, () => socket.config],
            () => {
                if (Object.values(hasLoaded.value).includes(false)) {
                    return;
                }

                if (!socket.config?.maintenance) {
                    if (route.path === "/block/maintenance") {
                        window.location.href = "/";
                        return;
                    }

                    el.style.opacity = "0";
                    setTimeout(() => el?.remove(), 300);
                } else if (socket.config?.maintenance === true) {
                    if (
                        route.path !== "/block/maintenance" &&
                        !auth.hasPermission("maintenance_bypass")
                    ) {
                        window.location.href = "/block/maintenance";
                        return;
                    }

                    el.style.opacity = "0";
                    setTimeout(() => el?.remove(), 300);
                }
            },
            { deep: true }
        );
    }
});
