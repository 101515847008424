import { DragonDropVue } from "dragon-drop-vue";

export default defineNuxtPlugin(nuxtApp => {
    const dragonDropOptions = {
        dragOverDebounceMs: 300,
        debugLog: false
    };

    nuxtApp.vueApp.use(DragonDropVue, dragonDropOptions);
});
