import { default as callbackyEQpN0HVvCMeta } from "/opt/buildhome/repo/pages/auth/steam/callback.vue?macro=true";
import { default as manual_45callback4rr2u8kOrBMeta } from "/opt/buildhome/repo/pages/auth/steam/manual-callback.vue?macro=true";
import { default as two_45factorPwALIszWs6Meta } from "/opt/buildhome/repo/pages/auth/two-factor.vue?macro=true";
import { default as bannedNRja6najbOMeta } from "/opt/buildhome/repo/pages/block/banned.vue?macro=true";
import { default as country0NxGnmYVQmMeta } from "/opt/buildhome/repo/pages/block/country.vue?macro=true";
import { default as maintenanceitCvMxIb9lMeta } from "/opt/buildhome/repo/pages/block/maintenance.vue?macro=true";
import { default as indexhIizz6a1EjMeta } from "/opt/buildhome/repo/pages/dashboard/affiliates/index.vue?macro=true";
import { default as _91id_93iKNLXlWR86Meta } from "/opt/buildhome/repo/pages/dashboard/announcements/[id].vue?macro=true";
import { default as indexvkAd3U6zaqMeta } from "/opt/buildhome/repo/pages/dashboard/announcements/index.vue?macro=true";
import { default as _91id_931G1Zbf3HhTMeta } from "/opt/buildhome/repo/pages/dashboard/bans/[id].vue?macro=true";
import { default as create7tUwaYBYieMeta } from "/opt/buildhome/repo/pages/dashboard/bans/create.vue?macro=true";
import { default as durations67LttRvnQvMeta } from "/opt/buildhome/repo/pages/dashboard/bans/durations.vue?macro=true";
import { default as indexPEJOScef6SMeta } from "/opt/buildhome/repo/pages/dashboard/bans/index.vue?macro=true";
import { default as reasonsFIBS3Ri9BcMeta } from "/opt/buildhome/repo/pages/dashboard/bans/reasons.vue?macro=true";
import { default as _91id_934OxjQZKPGgMeta } from "/opt/buildhome/repo/pages/dashboard/battles/[id].vue?macro=true";
import { default as indexfbfjKgl1LlMeta } from "/opt/buildhome/repo/pages/dashboard/battles/index.vue?macro=true";
import { default as indexf67hnl4ICcMeta } from "/opt/buildhome/repo/pages/dashboard/block-countries/index.vue?macro=true";
import { default as _91id_93JuusqRd19mMeta } from "/opt/buildhome/repo/pages/dashboard/bot-identities/[id].vue?macro=true";
import { default as createU5vgtizT35Meta } from "/opt/buildhome/repo/pages/dashboard/bot-identities/create.vue?macro=true";
import { default as indexRBCjh4QXfGMeta } from "/opt/buildhome/repo/pages/dashboard/bot-identities/index.vue?macro=true";
import { default as _91id_93xW7ZRTXjLCMeta } from "/opt/buildhome/repo/pages/dashboard/cases/[id].vue?macro=true";
import { default as create4nbPS56chFMeta } from "/opt/buildhome/repo/pages/dashboard/cases/create.vue?macro=true";
import { default as indexiqOnVrcAroMeta } from "/opt/buildhome/repo/pages/dashboard/cases/index.vue?macro=true";
import { default as _91id_93CniSPBIzccMeta } from "/opt/buildhome/repo/pages/dashboard/chat/channels/[id].vue?macro=true";
import { default as createoOzJbC4QBoMeta } from "/opt/buildhome/repo/pages/dashboard/chat/channels/create.vue?macro=true";
import { default as indexhNFYyRLTTMMeta } from "/opt/buildhome/repo/pages/dashboard/chat/channels/index.vue?macro=true";
import { default as _91id_93FfLNYni5StMeta } from "/opt/buildhome/repo/pages/dashboard/chat/filters/[id].vue?macro=true";
import { default as createeEMJ3Gjyu9Meta } from "/opt/buildhome/repo/pages/dashboard/chat/filters/create.vue?macro=true";
import { default as indexnvXlBEHY6SMeta } from "/opt/buildhome/repo/pages/dashboard/chat/filters/index.vue?macro=true";
import { default as indexFeZ9rdDRAaMeta } from "/opt/buildhome/repo/pages/dashboard/chat/messages/index.vue?macro=true";
import { default as _91id_93c9FnajA8rlMeta } from "/opt/buildhome/repo/pages/dashboard/chat/rules/[id].vue?macro=true";
import { default as createRsXbVlsFMkMeta } from "/opt/buildhome/repo/pages/dashboard/chat/rules/create.vue?macro=true";
import { default as indexLyYIN1oV22Meta } from "/opt/buildhome/repo/pages/dashboard/chat/rules/index.vue?macro=true";
import { default as _91uuid_93yLdojHxo2bMeta } from "/opt/buildhome/repo/pages/dashboard/coinflip/[uuid].vue?macro=true";
import { default as indexD66VmPpDWmMeta } from "/opt/buildhome/repo/pages/dashboard/coinflip/index.vue?macro=true";
import { default as _91id_93Im0z57QKx4Meta } from "/opt/buildhome/repo/pages/dashboard/cryptos/[id].vue?macro=true";
import { default as createCSxiJpFsAiMeta } from "/opt/buildhome/repo/pages/dashboard/cryptos/create.vue?macro=true";
import { default as index4gJRXDOpErMeta } from "/opt/buildhome/repo/pages/dashboard/cryptos/index.vue?macro=true";
import { default as _91id_93SWCqb3zOkyMeta } from "/opt/buildhome/repo/pages/dashboard/emojis/[id].vue?macro=true";
import { default as createswCQBfFbiVMeta } from "/opt/buildhome/repo/pages/dashboard/emojis/create.vue?macro=true";
import { default as indexQYMqi8jCNnMeta } from "/opt/buildhome/repo/pages/dashboard/emojis/index.vue?macro=true";
import { default as _91id_93MUt7iPO7ITMeta } from "/opt/buildhome/repo/pages/dashboard/faq/[id].vue?macro=true";
import { default as createTgrZKMSFEhMeta } from "/opt/buildhome/repo/pages/dashboard/faq/create.vue?macro=true";
import { default as indexxqWaEYJPqHMeta } from "/opt/buildhome/repo/pages/dashboard/faq/index.vue?macro=true";
import { default as indexhqalilaLqfMeta } from "/opt/buildhome/repo/pages/dashboard/games/index.vue?macro=true";
import { default as index9tr51Bg7EIMeta } from "/opt/buildhome/repo/pages/dashboard/health/index.vue?macro=true";
import { default as indexSEhdEZkNMjMeta } from "/opt/buildhome/repo/pages/dashboard/index.vue?macro=true";
import { default as _91uuid_93G6V3cuCEeTMeta } from "/opt/buildhome/repo/pages/dashboard/jackpot/[uuid].vue?macro=true";
import { default as index8stxSKxKHRMeta } from "/opt/buildhome/repo/pages/dashboard/jackpot/index.vue?macro=true";
import { default as _91id_93K2ZJ4Ug7KEMeta } from "/opt/buildhome/repo/pages/dashboard/kinguin/keys/[id].vue?macro=true";
import { default as createP6RkDk3GhLMeta } from "/opt/buildhome/repo/pages/dashboard/kinguin/keys/create.vue?macro=true";
import { default as indexJ26YCMavs1Meta } from "/opt/buildhome/repo/pages/dashboard/kinguin/keys/index.vue?macro=true";
import { default as _91id_93vJWeLw314vMeta } from "/opt/buildhome/repo/pages/dashboard/kinguin/products/[id].vue?macro=true";
import { default as createdYIrEsymdFMeta } from "/opt/buildhome/repo/pages/dashboard/kinguin/products/create.vue?macro=true";
import { default as indexGOZvHPYW66Meta } from "/opt/buildhome/repo/pages/dashboard/kinguin/products/index.vue?macro=true";
import { default as _91id_934tZUgHv4V9Meta } from "/opt/buildhome/repo/pages/dashboard/leaderboards/[id].vue?macro=true";
import { default as indexp2ePhts6fhMeta } from "/opt/buildhome/repo/pages/dashboard/leaderboards/index.vue?macro=true";
import { default as _91id_93jBwVVbZ96XMeta } from "/opt/buildhome/repo/pages/dashboard/leaderboards/periods/[id].vue?macro=true";
import { default as indexxPPMUxNxDRMeta } from "/opt/buildhome/repo/pages/dashboard/leaderboards/periods/index.vue?macro=true";
import { default as anjouan_45reportswjOb7AU6SQMeta } from "/opt/buildhome/repo/pages/dashboard/legal/anjouan-reports.vue?macro=true";
import { default as indexzac16NQNgMMeta } from "/opt/buildhome/repo/pages/dashboard/levels/index.vue?macro=true";
import { default as indexVSM5dstnPpMeta } from "/opt/buildhome/repo/pages/dashboard/logging/index.vue?macro=true";
import { default as indexYcV3MDm7VqMeta } from "/opt/buildhome/repo/pages/dashboard/mines/index.vue?macro=true";
import { default as indexqrGdgEYD4kMeta } from "/opt/buildhome/repo/pages/dashboard/openings/index.vue?macro=true";
import { default as _91id_93K55OSl0nUFMeta } from "/opt/buildhome/repo/pages/dashboard/payment/steam/[id].vue?macro=true";
import { default as createwEolstHx69Meta } from "/opt/buildhome/repo/pages/dashboard/payment/steam/create.vue?macro=true";
import { default as indexOiwwTgjLR0Meta } from "/opt/buildhome/repo/pages/dashboard/payment/steam/index.vue?macro=true";
import { default as _91id_93UQuGYO7IDiMeta } from "/opt/buildhome/repo/pages/dashboard/plinko/difficulty/[id].vue?macro=true";
import { default as create7uuSDl2wXOMeta } from "/opt/buildhome/repo/pages/dashboard/plinko/difficulty/create.vue?macro=true";
import { default as index0mK9r9juxBMeta } from "/opt/buildhome/repo/pages/dashboard/plinko/index.vue?macro=true";
import { default as _91id_93bcsCBbccLAMeta } from "/opt/buildhome/repo/pages/dashboard/proxies/[id].vue?macro=true";
import { default as createibxElUaXDtMeta } from "/opt/buildhome/repo/pages/dashboard/proxies/create.vue?macro=true";
import { default as index1Zxn2c75SIMeta } from "/opt/buildhome/repo/pages/dashboard/proxies/index.vue?macro=true";
import { default as _91id_93chJjx5MDeiMeta } from "/opt/buildhome/repo/pages/dashboard/rains/[id].vue?macro=true";
import { default as indexXgQZnc5nbIMeta } from "/opt/buildhome/repo/pages/dashboard/rains/index.vue?macro=true";
import { default as _91id_93uXQE6WYqC7Meta } from "/opt/buildhome/repo/pages/dashboard/roles/[id].vue?macro=true";
import { default as createhx5QxX7rwRMeta } from "/opt/buildhome/repo/pages/dashboard/roles/create.vue?macro=true";
import { default as indexxhLk1v8LUCMeta } from "/opt/buildhome/repo/pages/dashboard/roles/index.vue?macro=true";
import { default as indexdUc9gVelYMMeta } from "/opt/buildhome/repo/pages/dashboard/settings/index.vue?macro=true";
import { default as index5odW1Rw14KMeta } from "/opt/buildhome/repo/pages/dashboard/steam/bots/[id]/index/index.vue?macro=true";
import { default as inventoryKMUdtqRdTQMeta } from "/opt/buildhome/repo/pages/dashboard/steam/bots/[id]/index/inventory.vue?macro=true";
import { default as steam_45ui64pRriTItmMeta } from "/opt/buildhome/repo/pages/dashboard/steam/bots/[id]/index/steam-ui.vue?macro=true";
import { default as indexid4aIU9SCBMeta } from "/opt/buildhome/repo/pages/dashboard/steam/bots/[id]/index.vue?macro=true";
import { default as createuUIUazkLjqMeta } from "/opt/buildhome/repo/pages/dashboard/steam/bots/create.vue?macro=true";
import { default as indexyKEOZtmxoGMeta } from "/opt/buildhome/repo/pages/dashboard/steam/bots/index.vue?macro=true";
import { default as _91id_93VSsXHYEDcvMeta } from "/opt/buildhome/repo/pages/dashboard/steam/items/[id].vue?macro=true";
import { default as createRPEWJSvFTGMeta } from "/opt/buildhome/repo/pages/dashboard/steam/items/create.vue?macro=true";
import { default as indexZOuuDSNDg2Meta } from "/opt/buildhome/repo/pages/dashboard/steam/items/index.vue?macro=true";
import { default as _91id_93knT5NhHe20Meta } from "/opt/buildhome/repo/pages/dashboard/steam/trades/[id].vue?macro=true";
import { default as createRU6XqASDaFMeta } from "/opt/buildhome/repo/pages/dashboard/steam/trades/create.vue?macro=true";
import { default as indexagcFar0IaxMeta } from "/opt/buildhome/repo/pages/dashboard/steam/trades/index.vue?macro=true";
import { default as _91id_93GBESqT3bLbMeta } from "/opt/buildhome/repo/pages/dashboard/tower/difficulty/[id].vue?macro=true";
import { default as create3c81G7GFcuMeta } from "/opt/buildhome/repo/pages/dashboard/tower/difficulty/create.vue?macro=true";
import { default as index4zd2D83yHzMeta } from "/opt/buildhome/repo/pages/dashboard/tower/index.vue?macro=true";
import { default as _91uuid_93W82vFTwOteMeta } from "/opt/buildhome/repo/pages/dashboard/transactions/[uuid].vue?macro=true";
import { default as indexLiSameyGsSMeta } from "/opt/buildhome/repo/pages/dashboard/transactions/index.vue?macro=true";
import { default as indexTvrswlJi7gMeta } from "/opt/buildhome/repo/pages/dashboard/upgrader/index.vue?macro=true";
import { default as _91uuid_93DQVywhFYs8Meta } from "/opt/buildhome/repo/pages/dashboard/users/[uuid].vue?macro=true";
import { default as indexyrHdB6uvl8Meta } from "/opt/buildhome/repo/pages/dashboard/users/index.vue?macro=true";
import { default as wheelfEajlPwnElMeta } from "/opt/buildhome/repo/pages/dashboard/wheel.vue?macro=true";
import { default as christmasuGL3mbm096Meta } from "/opt/buildhome/repo/pages/events/christmas.vue?macro=true";
import { default as battles3w2icLjxqgMeta } from "/opt/buildhome/repo/pages/fairness/index/battles.vue?macro=true";
import { default as casesrxcpzJFymvMeta } from "/opt/buildhome/repo/pages/fairness/index/cases.vue?macro=true";
import { default as coinflipldyuup7S69Meta } from "/opt/buildhome/repo/pages/fairness/index/coinflip.vue?macro=true";
import { default as indexAIsx3augGMMeta } from "/opt/buildhome/repo/pages/fairness/index/index.vue?macro=true";
import { default as jackpotapw1FRZsVBMeta } from "/opt/buildhome/repo/pages/fairness/index/jackpot.vue?macro=true";
import { default as mines1u5XjAY2ESMeta } from "/opt/buildhome/repo/pages/fairness/index/mines.vue?macro=true";
import { default as tower7B93vKnuPjMeta } from "/opt/buildhome/repo/pages/fairness/index/tower.vue?macro=true";
import { default as upgraderuvnuX7mDhjMeta } from "/opt/buildhome/repo/pages/fairness/index/upgrader.vue?macro=true";
import { default as indexaaVFn0mnIiMeta } from "/opt/buildhome/repo/pages/fairness/index.vue?macro=true";
import { default as faq6z6qNnXVtzMeta } from "/opt/buildhome/repo/pages/faq.vue?macro=true";
import { default as _91uuid_93nqw88qkGMoMeta } from "/opt/buildhome/repo/pages/games/battles/[uuid].vue?macro=true";
import { default as createpEVqe2fLVnMeta } from "/opt/buildhome/repo/pages/games/battles/create.vue?macro=true";
import { default as indexoETaG4WhiZMeta } from "/opt/buildhome/repo/pages/games/battles/index.vue?macro=true";
import { default as _91id_931hcTpIMKeBMeta } from "/opt/buildhome/repo/pages/games/cases/[id].vue?macro=true";
import { default as indexhv7rvUDHMrMeta } from "/opt/buildhome/repo/pages/games/cases/index.vue?macro=true";
import { default as indexrRgLnLa8AFMeta } from "/opt/buildhome/repo/pages/games/coinflip/index.vue?macro=true";
import { default as historyTU1JhGlmXNMeta } from "/opt/buildhome/repo/pages/games/jackpot/history.vue?macro=true";
import { default as indexhyjCwdR7YYMeta } from "/opt/buildhome/repo/pages/games/jackpot/index.vue?macro=true";
import { default as indexVdUi6Jo0Z4Meta } from "/opt/buildhome/repo/pages/games/mines/index.vue?macro=true";
import { default as indexNhOGx9Am23Meta } from "/opt/buildhome/repo/pages/games/plinko/index.vue?macro=true";
import { default as indexxdiFUlGpNBMeta } from "/opt/buildhome/repo/pages/games/tower/index.vue?macro=true";
import { default as indexMY4CJLH0BEMeta } from "/opt/buildhome/repo/pages/games/upgrader/index.vue?macro=true";
import { default as indexL8e6xM1Zc5Meta } from "/opt/buildhome/repo/pages/games/wheel/index.vue?macro=true";
import { default as gamesY2feVSyL2SMeta } from "/opt/buildhome/repo/pages/games.vue?macro=true";
import { default as indexniDiYCWjuTMeta } from "/opt/buildhome/repo/pages/index.vue?macro=true";
import { default as leaderboard9eEat6qN4cMeta } from "/opt/buildhome/repo/pages/leaderboard.vue?macro=true";
import { default as marketplace_45partnerMP5okhCM49Meta } from "/opt/buildhome/repo/pages/marketplace-partner.vue?macro=true";
import { default as marketplace5o0SIhBPOLMeta } from "/opt/buildhome/repo/pages/marketplace.vue?macro=true";
import { default as callback7Ch3flc8poMeta } from "/opt/buildhome/repo/pages/oauth/[provider]/callback.vue?macro=true";
import { default as accountOwXIB6N01PMeta } from "/opt/buildhome/repo/pages/profile/account.vue?macro=true";
import { default as affiliateuRKDSNBQx4Meta } from "/opt/buildhome/repo/pages/profile/affiliate.vue?macro=true";
import { default as historyFIm1UT9EzqMeta } from "/opt/buildhome/repo/pages/profile/history.vue?macro=true";
import { default as securitycr2Q8wGqDXMeta } from "/opt/buildhome/repo/pages/profile/security.vue?macro=true";
import { default as settings3Dj8Rqvbp3Meta } from "/opt/buildhome/repo/pages/profile/settings.vue?macro=true";
import { default as transactionsz5TftAFUFtMeta } from "/opt/buildhome/repo/pages/profile/transactions.vue?macro=true";
import { default as verificationOM76TPEBVrMeta } from "/opt/buildhome/repo/pages/profile/verification.vue?macro=true";
import { default as profileg2OzvUzQjKMeta } from "/opt/buildhome/repo/pages/profile.vue?macro=true";
import { default as _91code_9367wZeFRg1lMeta } from "/opt/buildhome/repo/pages/r/[code].vue?macro=true";
import { default as rewards4u5pxZOeLLMeta } from "/opt/buildhome/repo/pages/rewards.vue?macro=true";
import { default as callbackigLYJxT8HKMeta } from "/opt/buildhome/repo/pages/skinify/callback.vue?macro=true";
import { default as vaultichsL13WnaMeta } from "/opt/buildhome/repo/pages/vault.vue?macro=true";
import { default as _91currency_93CbRpliW1lrMeta } from "/opt/buildhome/repo/pages/wallet/deposit/cryptos/[currency].vue?macro=true";
import { default as _91id_93GWCSU6EdlZMeta } from "/opt/buildhome/repo/pages/wallet/deposit/fiat/[id].vue?macro=true";
import { default as giftcardsnzMptc1ZOsMeta } from "/opt/buildhome/repo/pages/wallet/deposit/giftcards.vue?macro=true";
import { default as index90llUCMfYmMeta } from "/opt/buildhome/repo/pages/wallet/deposit/index.vue?macro=true";
import { default as _91currency_93oQWRBaewKNMeta } from "/opt/buildhome/repo/pages/wallet/withdraw/cryptos/[currency].vue?macro=true";
import { default as index8Htf9fyDHEMeta } from "/opt/buildhome/repo/pages/wallet/withdraw/index.vue?macro=true";
import { default as walletZSlO4FiQE2Meta } from "/opt/buildhome/repo/pages/wallet.vue?macro=true";
export default [
  {
    name: "auth-steam-callback",
    path: "/auth/steam/callback",
    component: () => import("/opt/buildhome/repo/pages/auth/steam/callback.vue").then(m => m.default || m)
  },
  {
    name: "auth-steam-manual-callback",
    path: "/auth/steam/manual-callback",
    component: () => import("/opt/buildhome/repo/pages/auth/steam/manual-callback.vue").then(m => m.default || m)
  },
  {
    name: "auth-two-factor",
    path: "/auth/two-factor",
    meta: two_45factorPwALIszWs6Meta || {},
    component: () => import("/opt/buildhome/repo/pages/auth/two-factor.vue").then(m => m.default || m)
  },
  {
    name: "block-banned",
    path: "/block/banned",
    meta: bannedNRja6najbOMeta || {},
    component: () => import("/opt/buildhome/repo/pages/block/banned.vue").then(m => m.default || m)
  },
  {
    name: "block-country",
    path: "/block/country",
    meta: country0NxGnmYVQmMeta || {},
    component: () => import("/opt/buildhome/repo/pages/block/country.vue").then(m => m.default || m)
  },
  {
    name: "block-maintenance",
    path: "/block/maintenance",
    meta: maintenanceitCvMxIb9lMeta || {},
    component: () => import("/opt/buildhome/repo/pages/block/maintenance.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-affiliates",
    path: "/dashboard/affiliates",
    meta: indexhIizz6a1EjMeta || {},
    component: () => import("/opt/buildhome/repo/pages/dashboard/affiliates/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-announcements-id",
    path: "/dashboard/announcements/:id()",
    component: () => import("/opt/buildhome/repo/pages/dashboard/announcements/[id].vue").then(m => m.default || m)
  },
  {
    name: "dashboard-announcements",
    path: "/dashboard/announcements",
    component: () => import("/opt/buildhome/repo/pages/dashboard/announcements/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-bans-id",
    path: "/dashboard/bans/:id()",
    meta: _91id_931G1Zbf3HhTMeta || {},
    component: () => import("/opt/buildhome/repo/pages/dashboard/bans/[id].vue").then(m => m.default || m)
  },
  {
    name: "dashboard-bans-create",
    path: "/dashboard/bans/create",
    meta: create7tUwaYBYieMeta || {},
    component: () => import("/opt/buildhome/repo/pages/dashboard/bans/create.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-bans-durations",
    path: "/dashboard/bans/durations",
    meta: durations67LttRvnQvMeta || {},
    component: () => import("/opt/buildhome/repo/pages/dashboard/bans/durations.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-bans",
    path: "/dashboard/bans",
    meta: indexPEJOScef6SMeta || {},
    component: () => import("/opt/buildhome/repo/pages/dashboard/bans/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-bans-reasons",
    path: "/dashboard/bans/reasons",
    meta: reasonsFIBS3Ri9BcMeta || {},
    component: () => import("/opt/buildhome/repo/pages/dashboard/bans/reasons.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-battles-id",
    path: "/dashboard/battles/:id()",
    component: () => import("/opt/buildhome/repo/pages/dashboard/battles/[id].vue").then(m => m.default || m)
  },
  {
    name: "dashboard-battles",
    path: "/dashboard/battles",
    meta: indexfbfjKgl1LlMeta || {},
    component: () => import("/opt/buildhome/repo/pages/dashboard/battles/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-block-countries",
    path: "/dashboard/block-countries",
    meta: indexf67hnl4ICcMeta || {},
    component: () => import("/opt/buildhome/repo/pages/dashboard/block-countries/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-bot-identities-id",
    path: "/dashboard/bot-identities/:id()",
    meta: _91id_93JuusqRd19mMeta || {},
    component: () => import("/opt/buildhome/repo/pages/dashboard/bot-identities/[id].vue").then(m => m.default || m)
  },
  {
    name: "dashboard-bot-identities-create",
    path: "/dashboard/bot-identities/create",
    meta: createU5vgtizT35Meta || {},
    component: () => import("/opt/buildhome/repo/pages/dashboard/bot-identities/create.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-bot-identities",
    path: "/dashboard/bot-identities",
    meta: indexRBCjh4QXfGMeta || {},
    component: () => import("/opt/buildhome/repo/pages/dashboard/bot-identities/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-cases-id",
    path: "/dashboard/cases/:id()",
    component: () => import("/opt/buildhome/repo/pages/dashboard/cases/[id].vue").then(m => m.default || m)
  },
  {
    name: "dashboard-cases-create",
    path: "/dashboard/cases/create",
    component: () => import("/opt/buildhome/repo/pages/dashboard/cases/create.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-cases",
    path: "/dashboard/cases",
    meta: indexiqOnVrcAroMeta || {},
    component: () => import("/opt/buildhome/repo/pages/dashboard/cases/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-chat-channels-id",
    path: "/dashboard/chat/channels/:id()",
    component: () => import("/opt/buildhome/repo/pages/dashboard/chat/channels/[id].vue").then(m => m.default || m)
  },
  {
    name: "dashboard-chat-channels-create",
    path: "/dashboard/chat/channels/create",
    component: () => import("/opt/buildhome/repo/pages/dashboard/chat/channels/create.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-chat-channels",
    path: "/dashboard/chat/channels",
    component: () => import("/opt/buildhome/repo/pages/dashboard/chat/channels/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-chat-filters-id",
    path: "/dashboard/chat/filters/:id()",
    component: () => import("/opt/buildhome/repo/pages/dashboard/chat/filters/[id].vue").then(m => m.default || m)
  },
  {
    name: "dashboard-chat-filters-create",
    path: "/dashboard/chat/filters/create",
    component: () => import("/opt/buildhome/repo/pages/dashboard/chat/filters/create.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-chat-filters",
    path: "/dashboard/chat/filters",
    component: () => import("/opt/buildhome/repo/pages/dashboard/chat/filters/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-chat-messages",
    path: "/dashboard/chat/messages",
    component: () => import("/opt/buildhome/repo/pages/dashboard/chat/messages/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-chat-rules-id",
    path: "/dashboard/chat/rules/:id()",
    component: () => import("/opt/buildhome/repo/pages/dashboard/chat/rules/[id].vue").then(m => m.default || m)
  },
  {
    name: "dashboard-chat-rules-create",
    path: "/dashboard/chat/rules/create",
    component: () => import("/opt/buildhome/repo/pages/dashboard/chat/rules/create.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-chat-rules",
    path: "/dashboard/chat/rules",
    component: () => import("/opt/buildhome/repo/pages/dashboard/chat/rules/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-coinflip-uuid",
    path: "/dashboard/coinflip/:uuid()",
    component: () => import("/opt/buildhome/repo/pages/dashboard/coinflip/[uuid].vue").then(m => m.default || m)
  },
  {
    name: "dashboard-coinflip",
    path: "/dashboard/coinflip",
    component: () => import("/opt/buildhome/repo/pages/dashboard/coinflip/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-cryptos-id",
    path: "/dashboard/cryptos/:id()",
    meta: _91id_93Im0z57QKx4Meta || {},
    component: () => import("/opt/buildhome/repo/pages/dashboard/cryptos/[id].vue").then(m => m.default || m)
  },
  {
    name: "dashboard-cryptos-create",
    path: "/dashboard/cryptos/create",
    meta: createCSxiJpFsAiMeta || {},
    component: () => import("/opt/buildhome/repo/pages/dashboard/cryptos/create.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-cryptos",
    path: "/dashboard/cryptos",
    meta: index4gJRXDOpErMeta || {},
    component: () => import("/opt/buildhome/repo/pages/dashboard/cryptos/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-emojis-id",
    path: "/dashboard/emojis/:id()",
    meta: _91id_93SWCqb3zOkyMeta || {},
    component: () => import("/opt/buildhome/repo/pages/dashboard/emojis/[id].vue").then(m => m.default || m)
  },
  {
    name: "dashboard-emojis-create",
    path: "/dashboard/emojis/create",
    meta: createswCQBfFbiVMeta || {},
    component: () => import("/opt/buildhome/repo/pages/dashboard/emojis/create.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-emojis",
    path: "/dashboard/emojis",
    meta: indexQYMqi8jCNnMeta || {},
    component: () => import("/opt/buildhome/repo/pages/dashboard/emojis/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-faq-id",
    path: "/dashboard/faq/:id()",
    meta: _91id_93MUt7iPO7ITMeta || {},
    component: () => import("/opt/buildhome/repo/pages/dashboard/faq/[id].vue").then(m => m.default || m)
  },
  {
    name: "dashboard-faq-create",
    path: "/dashboard/faq/create",
    meta: createTgrZKMSFEhMeta || {},
    component: () => import("/opt/buildhome/repo/pages/dashboard/faq/create.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-faq",
    path: "/dashboard/faq",
    meta: indexxqWaEYJPqHMeta || {},
    component: () => import("/opt/buildhome/repo/pages/dashboard/faq/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-games",
    path: "/dashboard/games",
    meta: indexhqalilaLqfMeta || {},
    component: () => import("/opt/buildhome/repo/pages/dashboard/games/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-health",
    path: "/dashboard/health",
    meta: index9tr51Bg7EIMeta || {},
    component: () => import("/opt/buildhome/repo/pages/dashboard/health/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard",
    path: "/dashboard",
    component: () => import("/opt/buildhome/repo/pages/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-jackpot-uuid",
    path: "/dashboard/jackpot/:uuid()",
    component: () => import("/opt/buildhome/repo/pages/dashboard/jackpot/[uuid].vue").then(m => m.default || m)
  },
  {
    name: "dashboard-jackpot",
    path: "/dashboard/jackpot",
    component: () => import("/opt/buildhome/repo/pages/dashboard/jackpot/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-kinguin-keys-id",
    path: "/dashboard/kinguin/keys/:id()",
    meta: _91id_93K2ZJ4Ug7KEMeta || {},
    component: () => import("/opt/buildhome/repo/pages/dashboard/kinguin/keys/[id].vue").then(m => m.default || m)
  },
  {
    name: "dashboard-kinguin-keys-create",
    path: "/dashboard/kinguin/keys/create",
    meta: createP6RkDk3GhLMeta || {},
    component: () => import("/opt/buildhome/repo/pages/dashboard/kinguin/keys/create.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-kinguin-keys",
    path: "/dashboard/kinguin/keys",
    meta: indexJ26YCMavs1Meta || {},
    component: () => import("/opt/buildhome/repo/pages/dashboard/kinguin/keys/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-kinguin-products-id",
    path: "/dashboard/kinguin/products/:id()",
    meta: _91id_93vJWeLw314vMeta || {},
    component: () => import("/opt/buildhome/repo/pages/dashboard/kinguin/products/[id].vue").then(m => m.default || m)
  },
  {
    name: "dashboard-kinguin-products-create",
    path: "/dashboard/kinguin/products/create",
    meta: createdYIrEsymdFMeta || {},
    component: () => import("/opt/buildhome/repo/pages/dashboard/kinguin/products/create.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-kinguin-products",
    path: "/dashboard/kinguin/products",
    meta: indexGOZvHPYW66Meta || {},
    component: () => import("/opt/buildhome/repo/pages/dashboard/kinguin/products/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-leaderboards-id",
    path: "/dashboard/leaderboards/:id()",
    component: () => import("/opt/buildhome/repo/pages/dashboard/leaderboards/[id].vue").then(m => m.default || m)
  },
  {
    name: "dashboard-leaderboards",
    path: "/dashboard/leaderboards",
    meta: indexp2ePhts6fhMeta || {},
    component: () => import("/opt/buildhome/repo/pages/dashboard/leaderboards/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-leaderboards-periods-id",
    path: "/dashboard/leaderboards/periods/:id()",
    component: () => import("/opt/buildhome/repo/pages/dashboard/leaderboards/periods/[id].vue").then(m => m.default || m)
  },
  {
    name: "dashboard-leaderboards-periods",
    path: "/dashboard/leaderboards/periods",
    meta: indexxPPMUxNxDRMeta || {},
    component: () => import("/opt/buildhome/repo/pages/dashboard/leaderboards/periods/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-legal-anjouan-reports",
    path: "/dashboard/legal/anjouan-reports",
    meta: anjouan_45reportswjOb7AU6SQMeta || {},
    component: () => import("/opt/buildhome/repo/pages/dashboard/legal/anjouan-reports.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-levels",
    path: "/dashboard/levels",
    meta: indexzac16NQNgMMeta || {},
    component: () => import("/opt/buildhome/repo/pages/dashboard/levels/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-logging",
    path: "/dashboard/logging",
    meta: indexVSM5dstnPpMeta || {},
    component: () => import("/opt/buildhome/repo/pages/dashboard/logging/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-mines",
    path: "/dashboard/mines",
    meta: indexYcV3MDm7VqMeta || {},
    component: () => import("/opt/buildhome/repo/pages/dashboard/mines/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-openings",
    path: "/dashboard/openings",
    meta: indexqrGdgEYD4kMeta || {},
    component: () => import("/opt/buildhome/repo/pages/dashboard/openings/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-payment-steam-id",
    path: "/dashboard/payment/steam/:id()",
    component: () => import("/opt/buildhome/repo/pages/dashboard/payment/steam/[id].vue").then(m => m.default || m)
  },
  {
    name: "dashboard-payment-steam-create",
    path: "/dashboard/payment/steam/create",
    component: () => import("/opt/buildhome/repo/pages/dashboard/payment/steam/create.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-payment-steam",
    path: "/dashboard/payment/steam",
    component: () => import("/opt/buildhome/repo/pages/dashboard/payment/steam/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-plinko-difficulty-id",
    path: "/dashboard/plinko/difficulty/:id()",
    meta: _91id_93UQuGYO7IDiMeta || {},
    component: () => import("/opt/buildhome/repo/pages/dashboard/plinko/difficulty/[id].vue").then(m => m.default || m)
  },
  {
    name: "dashboard-plinko-difficulty-create",
    path: "/dashboard/plinko/difficulty/create",
    meta: create7uuSDl2wXOMeta || {},
    component: () => import("/opt/buildhome/repo/pages/dashboard/plinko/difficulty/create.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-plinko",
    path: "/dashboard/plinko",
    meta: index0mK9r9juxBMeta || {},
    component: () => import("/opt/buildhome/repo/pages/dashboard/plinko/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-proxies-id",
    path: "/dashboard/proxies/:id()",
    component: () => import("/opt/buildhome/repo/pages/dashboard/proxies/[id].vue").then(m => m.default || m)
  },
  {
    name: "dashboard-proxies-create",
    path: "/dashboard/proxies/create",
    component: () => import("/opt/buildhome/repo/pages/dashboard/proxies/create.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-proxies",
    path: "/dashboard/proxies",
    component: () => import("/opt/buildhome/repo/pages/dashboard/proxies/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-rains-id",
    path: "/dashboard/rains/:id()",
    component: () => import("/opt/buildhome/repo/pages/dashboard/rains/[id].vue").then(m => m.default || m)
  },
  {
    name: "dashboard-rains",
    path: "/dashboard/rains",
    component: () => import("/opt/buildhome/repo/pages/dashboard/rains/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-roles-id",
    path: "/dashboard/roles/:id()",
    meta: _91id_93uXQE6WYqC7Meta || {},
    component: () => import("/opt/buildhome/repo/pages/dashboard/roles/[id].vue").then(m => m.default || m)
  },
  {
    name: "dashboard-roles-create",
    path: "/dashboard/roles/create",
    meta: createhx5QxX7rwRMeta || {},
    component: () => import("/opt/buildhome/repo/pages/dashboard/roles/create.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-roles",
    path: "/dashboard/roles",
    meta: indexxhLk1v8LUCMeta || {},
    component: () => import("/opt/buildhome/repo/pages/dashboard/roles/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-settings",
    path: "/dashboard/settings",
    meta: indexdUc9gVelYMMeta || {},
    component: () => import("/opt/buildhome/repo/pages/dashboard/settings/index.vue").then(m => m.default || m)
  },
  {
    name: indexid4aIU9SCBMeta?.name,
    path: "/dashboard/steam/bots/:id()",
    component: () => import("/opt/buildhome/repo/pages/dashboard/steam/bots/[id]/index.vue").then(m => m.default || m),
    children: [
  {
    name: "dashboard-steam-bots-id-index",
    path: "",
    component: () => import("/opt/buildhome/repo/pages/dashboard/steam/bots/[id]/index/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-steam-bots-id-index-inventory",
    path: "inventory",
    component: () => import("/opt/buildhome/repo/pages/dashboard/steam/bots/[id]/index/inventory.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-steam-bots-id-index-steam-ui",
    path: "steam-ui",
    component: () => import("/opt/buildhome/repo/pages/dashboard/steam/bots/[id]/index/steam-ui.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "dashboard-steam-bots-create",
    path: "/dashboard/steam/bots/create",
    component: () => import("/opt/buildhome/repo/pages/dashboard/steam/bots/create.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-steam-bots",
    path: "/dashboard/steam/bots",
    component: () => import("/opt/buildhome/repo/pages/dashboard/steam/bots/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-steam-items-id",
    path: "/dashboard/steam/items/:id()",
    component: () => import("/opt/buildhome/repo/pages/dashboard/steam/items/[id].vue").then(m => m.default || m)
  },
  {
    name: "dashboard-steam-items-create",
    path: "/dashboard/steam/items/create",
    component: () => import("/opt/buildhome/repo/pages/dashboard/steam/items/create.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-steam-items",
    path: "/dashboard/steam/items",
    component: () => import("/opt/buildhome/repo/pages/dashboard/steam/items/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-steam-trades-id",
    path: "/dashboard/steam/trades/:id()",
    component: () => import("/opt/buildhome/repo/pages/dashboard/steam/trades/[id].vue").then(m => m.default || m)
  },
  {
    name: "dashboard-steam-trades-create",
    path: "/dashboard/steam/trades/create",
    component: () => import("/opt/buildhome/repo/pages/dashboard/steam/trades/create.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-steam-trades",
    path: "/dashboard/steam/trades",
    component: () => import("/opt/buildhome/repo/pages/dashboard/steam/trades/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-tower-difficulty-id",
    path: "/dashboard/tower/difficulty/:id()",
    meta: _91id_93GBESqT3bLbMeta || {},
    component: () => import("/opt/buildhome/repo/pages/dashboard/tower/difficulty/[id].vue").then(m => m.default || m)
  },
  {
    name: "dashboard-tower-difficulty-create",
    path: "/dashboard/tower/difficulty/create",
    meta: create3c81G7GFcuMeta || {},
    component: () => import("/opt/buildhome/repo/pages/dashboard/tower/difficulty/create.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-tower",
    path: "/dashboard/tower",
    meta: index4zd2D83yHzMeta || {},
    component: () => import("/opt/buildhome/repo/pages/dashboard/tower/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-transactions-uuid",
    path: "/dashboard/transactions/:uuid()",
    meta: _91uuid_93W82vFTwOteMeta || {},
    component: () => import("/opt/buildhome/repo/pages/dashboard/transactions/[uuid].vue").then(m => m.default || m)
  },
  {
    name: "dashboard-transactions",
    path: "/dashboard/transactions",
    meta: indexLiSameyGsSMeta || {},
    component: () => import("/opt/buildhome/repo/pages/dashboard/transactions/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-upgrader",
    path: "/dashboard/upgrader",
    meta: indexTvrswlJi7gMeta || {},
    component: () => import("/opt/buildhome/repo/pages/dashboard/upgrader/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-users-uuid",
    path: "/dashboard/users/:uuid()",
    meta: _91uuid_93DQVywhFYs8Meta || {},
    component: () => import("/opt/buildhome/repo/pages/dashboard/users/[uuid].vue").then(m => m.default || m)
  },
  {
    name: "dashboard-users",
    path: "/dashboard/users",
    meta: indexyrHdB6uvl8Meta || {},
    component: () => import("/opt/buildhome/repo/pages/dashboard/users/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-wheel",
    path: "/dashboard/wheel",
    component: () => import("/opt/buildhome/repo/pages/dashboard/wheel.vue").then(m => m.default || m)
  },
  {
    name: "events-christmas",
    path: "/events/christmas",
    component: () => import("/opt/buildhome/repo/pages/events/christmas.vue").then(m => m.default || m)
  },
  {
    name: indexaaVFn0mnIiMeta?.name,
    path: "/fairness",
    component: () => import("/opt/buildhome/repo/pages/fairness/index.vue").then(m => m.default || m),
    children: [
  {
    name: "fairness-index-battles",
    path: "battles",
    component: () => import("/opt/buildhome/repo/pages/fairness/index/battles.vue").then(m => m.default || m)
  },
  {
    name: "fairness-index-cases",
    path: "cases",
    component: () => import("/opt/buildhome/repo/pages/fairness/index/cases.vue").then(m => m.default || m)
  },
  {
    name: "fairness-index-coinflip",
    path: "coinflip",
    component: () => import("/opt/buildhome/repo/pages/fairness/index/coinflip.vue").then(m => m.default || m)
  },
  {
    name: "fairness-index",
    path: "",
    component: () => import("/opt/buildhome/repo/pages/fairness/index/index.vue").then(m => m.default || m)
  },
  {
    name: "fairness-index-jackpot",
    path: "jackpot",
    component: () => import("/opt/buildhome/repo/pages/fairness/index/jackpot.vue").then(m => m.default || m)
  },
  {
    name: "fairness-index-mines",
    path: "mines",
    component: () => import("/opt/buildhome/repo/pages/fairness/index/mines.vue").then(m => m.default || m)
  },
  {
    name: "fairness-index-tower",
    path: "tower",
    component: () => import("/opt/buildhome/repo/pages/fairness/index/tower.vue").then(m => m.default || m)
  },
  {
    name: "fairness-index-upgrader",
    path: "upgrader",
    component: () => import("/opt/buildhome/repo/pages/fairness/index/upgrader.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "faq",
    path: "/faq",
    component: () => import("/opt/buildhome/repo/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: "games",
    path: "/games",
    component: () => import("/opt/buildhome/repo/pages/games.vue").then(m => m.default || m),
    children: [
  {
    name: "games-battles-uuid",
    path: "battles/:uuid()",
    component: () => import("/opt/buildhome/repo/pages/games/battles/[uuid].vue").then(m => m.default || m)
  },
  {
    name: "games-battles-create",
    path: "battles/create",
    component: () => import("/opt/buildhome/repo/pages/games/battles/create.vue").then(m => m.default || m)
  },
  {
    name: "games-battles",
    path: "battles",
    component: () => import("/opt/buildhome/repo/pages/games/battles/index.vue").then(m => m.default || m)
  },
  {
    name: "games-cases-id",
    path: "cases/:id()",
    component: () => import("/opt/buildhome/repo/pages/games/cases/[id].vue").then(m => m.default || m)
  },
  {
    name: "games-cases",
    path: "cases",
    component: () => import("/opt/buildhome/repo/pages/games/cases/index.vue").then(m => m.default || m)
  },
  {
    name: "games-coinflip",
    path: "coinflip",
    component: () => import("/opt/buildhome/repo/pages/games/coinflip/index.vue").then(m => m.default || m)
  },
  {
    name: "games-jackpot-history",
    path: "jackpot/history",
    component: () => import("/opt/buildhome/repo/pages/games/jackpot/history.vue").then(m => m.default || m)
  },
  {
    name: "games-jackpot",
    path: "jackpot",
    component: () => import("/opt/buildhome/repo/pages/games/jackpot/index.vue").then(m => m.default || m)
  },
  {
    name: "games-mines",
    path: "mines",
    component: () => import("/opt/buildhome/repo/pages/games/mines/index.vue").then(m => m.default || m)
  },
  {
    name: "games-plinko",
    path: "plinko",
    component: () => import("/opt/buildhome/repo/pages/games/plinko/index.vue").then(m => m.default || m)
  },
  {
    name: "games-tower",
    path: "tower",
    component: () => import("/opt/buildhome/repo/pages/games/tower/index.vue").then(m => m.default || m)
  },
  {
    name: "games-upgrader",
    path: "upgrader",
    component: () => import("/opt/buildhome/repo/pages/games/upgrader/index.vue").then(m => m.default || m)
  },
  {
    name: "games-wheel",
    path: "wheel",
    component: () => import("/opt/buildhome/repo/pages/games/wheel/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "index",
    path: "/",
    component: () => import("/opt/buildhome/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "leaderboard",
    path: "/leaderboard",
    component: () => import("/opt/buildhome/repo/pages/leaderboard.vue").then(m => m.default || m)
  },
  {
    name: "marketplace-partner",
    path: "/marketplace-partner",
    component: () => import("/opt/buildhome/repo/pages/marketplace-partner.vue").then(m => m.default || m)
  },
  {
    name: "marketplace",
    path: "/marketplace",
    meta: marketplace5o0SIhBPOLMeta || {},
    component: () => import("/opt/buildhome/repo/pages/marketplace.vue").then(m => m.default || m)
  },
  {
    name: "oauth-provider-callback",
    path: "/oauth/:provider()/callback",
    component: () => import("/opt/buildhome/repo/pages/oauth/[provider]/callback.vue").then(m => m.default || m)
  },
  {
    name: "profile",
    path: "/profile",
    meta: profileg2OzvUzQjKMeta || {},
    component: () => import("/opt/buildhome/repo/pages/profile.vue").then(m => m.default || m),
    children: [
  {
    name: "profile-account",
    path: "account",
    component: () => import("/opt/buildhome/repo/pages/profile/account.vue").then(m => m.default || m)
  },
  {
    name: "profile-affiliate",
    path: "affiliate",
    component: () => import("/opt/buildhome/repo/pages/profile/affiliate.vue").then(m => m.default || m)
  },
  {
    name: "profile-history",
    path: "history",
    component: () => import("/opt/buildhome/repo/pages/profile/history.vue").then(m => m.default || m)
  },
  {
    name: "profile-security",
    path: "security",
    component: () => import("/opt/buildhome/repo/pages/profile/security.vue").then(m => m.default || m)
  },
  {
    name: "profile-settings",
    path: "settings",
    component: () => import("/opt/buildhome/repo/pages/profile/settings.vue").then(m => m.default || m)
  },
  {
    name: "profile-transactions",
    path: "transactions",
    component: () => import("/opt/buildhome/repo/pages/profile/transactions.vue").then(m => m.default || m)
  },
  {
    name: "profile-verification",
    path: "verification",
    component: () => import("/opt/buildhome/repo/pages/profile/verification.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "r-code",
    path: "/r/:code()",
    component: () => import("/opt/buildhome/repo/pages/r/[code].vue").then(m => m.default || m)
  },
  {
    name: "rewards",
    path: "/rewards",
    meta: rewards4u5pxZOeLLMeta || {},
    component: () => import("/opt/buildhome/repo/pages/rewards.vue").then(m => m.default || m)
  },
  {
    name: "skinify-callback",
    path: "/skinify/callback",
    component: () => import("/opt/buildhome/repo/pages/skinify/callback.vue").then(m => m.default || m)
  },
  {
    name: "vault",
    path: "/vault",
    component: () => import("/opt/buildhome/repo/pages/vault.vue").then(m => m.default || m)
  },
  {
    name: "wallet",
    path: "/wallet",
    meta: walletZSlO4FiQE2Meta || {},
    component: () => import("/opt/buildhome/repo/pages/wallet.vue").then(m => m.default || m),
    children: [
  {
    name: "wallet-deposit-cryptos-currency",
    path: "deposit/cryptos/:currency()",
    component: () => import("/opt/buildhome/repo/pages/wallet/deposit/cryptos/[currency].vue").then(m => m.default || m)
  },
  {
    name: "wallet-deposit-fiat-id",
    path: "deposit/fiat/:id()",
    component: () => import("/opt/buildhome/repo/pages/wallet/deposit/fiat/[id].vue").then(m => m.default || m)
  },
  {
    name: "wallet-deposit-giftcards",
    path: "deposit/giftcards",
    component: () => import("/opt/buildhome/repo/pages/wallet/deposit/giftcards.vue").then(m => m.default || m)
  },
  {
    name: "wallet-deposit",
    path: "deposit",
    component: () => import("/opt/buildhome/repo/pages/wallet/deposit/index.vue").then(m => m.default || m)
  },
  {
    name: "wallet-withdraw-cryptos-currency",
    path: "withdraw/cryptos/:currency()",
    component: () => import("/opt/buildhome/repo/pages/wallet/withdraw/cryptos/[currency].vue").then(m => m.default || m)
  },
  {
    name: "wallet-withdraw",
    path: "withdraw",
    component: () => import("/opt/buildhome/repo/pages/wallet/withdraw/index.vue").then(m => m.default || m)
  }
]
  }
]