import {
    ModalsFreePerks,
    ModalsGamesWinModal,
    ModalsLoginNotice
} from "#components";
import { useLocalStorage, useStorage } from "@vueuse/core";
import { defineStore } from "pinia";

export const useAuthStore = defineStore("auth", () => {
    const generateRandomClientSeed = () => {
        const str = Math.random().toString(36).substring(2);
        return str.replace(/[^a-zA-Z0-9]/g, "");
    };

    const config = useRuntimeConfig();
    const route = useRoute();
    const user = ref<UserInterface | null>(null);
    const token = useStorage<string | null>("token", null, localStorage);
    const referral = useCookie("referral");
    const clientSeed = useStorage<string | null>(
        "clientSeed",
        generateRandomClientSeed(),
        localStorage
    );
    const modal = useModal();
    const authToken = useStorage("auth_token", null, localStorage);
    const oldToken = useLocalStorage<string>("oldToken", null);

    const load = async () => {
        if ((clientSeed.value?.length ?? 0) < 2) {
            clientSeed.value = generateRandomClientSeed();
        }

        if (token.value) {
            return fetchUser();
        }
    };

    const login = async (captchaToken: string) => {
        return useApi("steam/redirect", {
            method: "GET",
            query: {
                captchaToken
            }
        }).then(({ data }: any) => {
            authToken.value = data.value.token;
            window.location.href = data.value.url;
        });
    };

    const callback = async (queries: any, manual: boolean = false) => {
        if (authToken.value) {
            queries.token = authToken.value;
        }

        return fetch(
            config.public.apiUrl +
                (manual ? "steam/manual-login" : "steam/callback") +
                "?" +
                new URLSearchParams(queries).toString(),
            {
                method: "GET"
            }
        )
            .then(res => res.json())
            .then(res => setToken(res.token));
    };

    const setToken = (newToken: string) => {
        token.value = newToken;
        return fetchUser();
    };

    const setClientSeed = (newClientSeed: string) => {
        clientSeed.value = newClientSeed;
    };

    const logout = async () => {
        const { error } = await useApi("auth/logout", {
            method: "DELETE"
        });

        if (error.value) {
            return;
        }

        localStorage.removeItem("token");
        token.value = null;
        user.value = null;

        if (oldToken.value) {
            return setToken(oldToken.value).then(() => {
                oldToken.value = null;
                window.location.href = "/";
            });
        }

        window.location.href = "/";
    };

    const checkForFullBan = async () => {
        const fullBan = user.value?.activeBans?.find(b => b.type === "full");

        if (!fullBan || route.path === "/block/banned") {
            return;
        }

        // todo: use nuxt
        window.location.href = "/block/banned";
    };

    const checkForUnclaimed = async () => {
        if (
            route.path.startsWith("/dashboard") ||
            route.path.startsWith("/block/")
        ) {
            return;
        }

        const { data, error } = await useApi("claims", {
            method: "get"
        });

        if (error.value || !data.value) {
            return;
        }

        if (modal.isOpen.value) {
            modal.close();
            await new Promise(resolve => setTimeout(resolve, 500));
        }

        modal.open(ModalsGamesWinModal, data.value as any);
    };

    const fetchUser = async (complete: boolean = true) => {
        const { data, error } = await useApi<{ user: UserInterface }>(
            "auth/me",
            {
                headers: {
                    authorization: isLogged.value
                        ? undefined
                        : (("Bearer " + token.value) as any)
                },
                watch: false
            }
        );

        if (error.value) {
            return false;
        }

        user.value = data.value?.user ?? null;

        if (complete) {
            await checkForFullBan();
            await checkForUnclaimed();
        }

        if (user.value && !user.value.referrer && referral.value) {
            await setAffiliateCode(referral.value);
        }

        return true;
    };

    const isLogged = computed(() => {
        return user.value && token.value;
    });

    const hasPermission = (permission: string) => {
        if (!user.value) {
            return false;
        }

        const isRoot = user.value.roles.some(role => role.root);

        const isAllowed = user.value.roles.some((role: RoleInterface) =>
            role.permissions?.some(
                p => p.slug === permission && p.allowed === true
            )
        );

        const isDenied = user.value.roles.some((role: RoleInterface) =>
            role.permissions?.some(
                p => p.slug === permission && p.allowed === false
            )
        );

        return isRoot || (isAllowed && !isDenied);
    };

    const openLoginNotice = () => {
        modal.open(ModalsLoginNotice);
    };

    const setAffiliateCode = async (
        code: string,
        skipModal: boolean = false
    ) => {
        if (!isLogged.value) {
            return;
        }
        if (user.value?.referrer) {
            return;
        }

        const { error, data } = await useApi<{ data: UserReferralInterface }>(
            "user/referrals/set-referrer",
            {
                method: "POST",
                body: {
                    code
                }
            }
        );

        if (error.value) {
            return;
        }

        user.value!.referrer = data.value!.data;

        referral.value = null;

        if (!skipModal) {
            modal.open(ModalsFreePerks);
        }
    };

    return {
        isLogged,
        user,
        token,
        clientSeed,
        generateRandomClientSeed,
        load,
        login,
        callback,
        logout,
        hasPermission,
        setToken,
        setClientSeed,
        checkForUnclaimed,
        fetchUser,
        openLoginNotice,
        setAffiliateCode
    };
});
