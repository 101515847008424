<script setup lang="ts">
// todo: useImage vueuse for lazy load (make a lazyimage component or something)
const props = withDefaults(
    defineProps<{
        item: SteamItemInterface;
        blur?: boolean;
        dots?: boolean;
        price?: number;
        glow?: boolean;
        glowOpacity?: number;
        rotator?: boolean;
        pulse?: boolean;
        glowVariant?: "normal" | "medium" | "small";
    }>(),
    {
        blur: true,
        dots: true,
        glow: true,
        glowOpacity: 1,
        rotator: true,
        pulse: false,
        glowVariant: "normal"
    }
);
const itemPrice = computed(() => props.price ?? props.item?.price ?? 0);

const backgroundImageInner = computed(() => {
    return `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='100' ry='100' stroke='${encodeURIComponent(itemNameColorByPrice(itemPrice.value))}FF' stroke-width='3' stroke-dasharray='10%25%2c 5%25' stroke-dashoffset='0' stroke-linecap='butt'/%3e%3c/svg%3e")`;
});

const backgroundImageOuter = computed(() => {
    return `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='100' ry='100' stroke='${encodeURIComponent(itemNameColorByPrice(itemPrice.value))}FF' stroke-width='3' stroke-dasharray='20%25%2c 20%25' stroke-dashoffset='0' stroke-linecap='butt'/%3e%3c/svg%3e")`;
});

const { itemNameColorByPrice } = useHelper();
</script>

<template>
    <div class="relative select-none">
        <div
            v-if="rotator"
            class="inner-rotator absolute left-1/2 top-1/2 w-2/4 -translate-x-1/2 -translate-y-1/2"
            :style="{
                backgroundImage: backgroundImageInner,
                aspectRatio: 1
            }"
        />

        <div
            v-if="rotator"
            class="outer-rotator absolute left-1/2 top-1/2 w-3/4 -translate-x-1/2 -translate-y-1/2 opacity-20"
            :style="{
                backgroundImage: backgroundImageOuter,
                aspectRatio: 1
            }"
        />

        <!-- temp revert glow -->
        <div
            v-if="blur && glow"
            class="absolute left-1/2 top-1/2 w-3/4 -translate-x-1/2 -translate-y-1/2"
            :class="{
                'animate-pulse': pulse,
                'blur-[10px]': glowVariant === 'small',
                '!blur-[30px]': glowVariant === 'medium',
                'blur-[30px]': glowVariant === 'normal'
            }"
            :style="{
                opacity: glowOpacity,
                aspectRatio: 1,
                backgroundColor: itemNameColorByPrice(itemPrice)
            }"
        />

        <!-- <img
            :src="`/images/skins/${itemNameColorByPrice(itemPrice).replace('#', '')}.png`"
            alt="Skin blur"
            class="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 rounded-xl"
            :class="{
                '!scale-110': glowVariant === 'small',
                '!scale-[2]': glowVariant === 'medium',
                '!scale-[3.5]': glowVariant === 'normal'
            }"
            loading="lazy"
        /> -->

        <!-- <ImageLazy
            v-if="props.item"
            :src="item.image"
            :alt="item.market_hash_name"
            class="relative mx-auto size-full scale-75"
            style="aspect-ratio: 1"
        /> -->
        <img :src="props.item?.image" alt="Skin" class="relative mx-auto size-full scale-75" style="aspect-ratio: 1">
    </div>
</template>

<style scoped lang="scss">
@keyframes fullRotate {
    0% {
        transform: rotate(0deg) translate(-50%, -50%);
    }
    100% {
        transform: rotate(360deg) translate(-50%, -50%);
    }
}

@keyframes fullRotateReverse {
    0% {
        transform: rotate(0deg) translate(-50%, -50%);
    }
    100% {
        transform: rotate(-360deg) translate(-50%, -50%);
    }
}

.outer-rotator {
    transform-origin: 0% 0%;
    border-radius: 100%;
    opacity: 0.2;
    animation: fullRotateReverse 15s linear infinite reverse;
}
.inner-rotator {
    transform-origin: 0% 0%;
    border-radius: 100%;
    animation: fullRotate 25s linear infinite reverse;
}
</style>
